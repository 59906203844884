<template>
  <v-list dense nav>
    <template v-for="project in projects">
      <v-list-item
        :key="`project-${project.projectId}`"
        :to="createTargetRoute(project)"
      >
        <!-- Project icon -->
        <v-list-item-avatar>
          <project-icon :project="project"></project-icon>
        </v-list-item-avatar>

        <!-- content -->
        <v-list-item-content>
          <!-- Project name -->
          <v-list-item-title>{{ project.projectName }}</v-list-item-title>

          <!-- Project Template name -->
          <v-list-item-subtitle>{{
            displayProjectDate(project)
          }}</v-list-item-subtitle>

          <!-- Project status -->
          <v-list-item-subtitle>
            <project-status :project="project"></project-status>
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action class="mr-2">
          <v-simple-checkbox
            dense
            @click="handleCheckboxClick($event, project)"
            :value="isSelected(project)"
          ></v-simple-checkbox>
        </v-list-item-action>
      </v-list-item>

      <v-divider :key="`project-divider-${project.projectId}`"></v-divider>
    </template>
  </v-list>
</template>

<script>
// model
import { projectDateToShortLocalString } from "@/model/workflow/project/projectModel";
import { moduleNames } from "@/model/solution/moduleModel";

// router
import { createModuleItemContentRouteLocation } from "@/router/solution/solutionRoutes";
import { eventNames } from "@/model/common/events/eventConst";

export default {
  name: "ProjectList",
  components: {
    ProjectStatus: () => import("@/components/project/ProjectStatus"),
    ProjectIcon: () => import("@/components/project/ProjectIcon")
  },
  data() {
    return {
      selectedProjects: []
    };
  },
  props: {
    /**
     * @type {{projectId: Number, creationDate: string, definitionId: Number, workflowInstanceId: Number, workflowTypeId: Number, projectName: String, recordId: Number, subject: String, owner: String, projectManagerId: Number, templateId: Number, templateName: String, workflowInstanceId: number, status: number, isOverdue: Boolean, isSuspended: false, canAccess: Boolean, canCancel: Boolean, canChangeOwnership: Boolean, canReassign: Boolean, canSuspend: Boolean}[]}
     */
    projects: undefined,
    selectAllProjects: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    /**
     * create Project Target Route
     * @param {{projectId: Number, creationDate: string, definitionId: Number, workflowInstanceId: Number, workflowTypeId: Number, projectName: String, recordId: Number, subject: String, owner: String, projectManagerId: Number, templateId: Number, templateName: String, workflowInstanceId: number, status: number, isOverdue: Boolean, isSuspended: false, canAccess: Boolean, canCancel: Boolean, canChangeOwnership: Boolean, canReassign: Boolean, canSuspend: Boolean}} project
     * @return {{name: (String|string), params: {id: (Number|number)}}}
     */
    createTargetRoute(project) {
      const id = project?.projectId ?? -1;

      return id >= 0
        ? createModuleItemContentRouteLocation(moduleNames.Projects, id)
        : undefined;
    },
    /**
     * display Project Date
     * @param {{projectId: Number, creationDate: string, definitionId: Number, workflowInstanceId: Number, workflowTypeId: Number, projectName: String, recordId: Number, subject: String, owner: String, projectManagerId: Number, templateId: Number, templateName: String, workflowInstanceId: number, status: number, isOverdue: Boolean, isSuspended: false, canAccess: Boolean, canCancel: Boolean, canChangeOwnership: Boolean, canReassign: Boolean, canSuspend: Boolean}} project Project
     * @return {string|string}
     */
    displayProjectDate(project) {
      return projectDateToShortLocalString(project);
    },

    /**
     * Event on Checkbox Clicked
     * @param event
     * @param project
     */
    handleCheckboxClick(event, project) {
      // To Stop Navigation
      event.preventDefault();

      const index = this.selectedProjects.indexOf(project);
      if (index > -1) {
        this.selectedProjects.splice(index, 1); // Remove from selectedProjects
      } else {
        this.selectedProjects.push(project); // Add to selectedProjects
      }
      this.emitProjectEvent(true);
    },

    /**
     * Determine if project is selected
     * @param project
     * @return {boolean}
     */
    isSelected(project) {
      return this.selectedProjects.includes(project);
    },

    /**
     * Emit Project Event
     * @param setIndeterminate
     */
    emitProjectEvent(setIndeterminate = false) {
      const payload = {
        projects: this.selectedProjects,
        setIndeterminate: setIndeterminate
      };

      this.$emit(eventNames.itemSelected, payload);
    }
  },

  watch: {
    selectAllProjects() {
      if (this.selectAllProjects) {
        this.selectedProjects = [...this.projects];
      } else {
        this.selectedProjects = [];
      }
      this.emitProjectEvent();
    },

    projects: {
      handler() {
        this.selectedProjects = [];
        this.emitProjectEvent();
      },
      deep: true
    }
  }
};
</script>
